<template>
  <div class="payment-pse">
    <div class="text-payu-logo">
      <img src="@/assets/icons/logo-pse.svg" alt="logo pse" />
      <p>
        Selecciona la entidad financiera para continuar con el pago, recuerda
        que debes tener una cuenta habilitada para realizar pagos por internet.
        El tiempo de entrega empezará a contar una vez recibamos la confirmación
        de pago por parte de la entidad.
      </p>
    </div>
    <div class="form-datos payment-input-methods">
      <div class="position-relative payment-input-left">
        <select v-model="banco" class="payment-input w-100">
          <option value="">Banco</option>
          <option
            v-for="(banco1, index) in bancos"
            :key="index"
            :value="banco1.pseCode"
            >{{
              banco1.description
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}</option
          >
        </select>
        <span v-if="validateBanco" class="msgError"
          >Este campo es obligatorio</span
        >
        <span v-if="banco" class="newlatter-error-input">Banco</span>
      </div>
      <div class="payment-input-left">
        <button
          class="btn-primary btn-finalize-payment w-100"
          style="min-height: 60px"
          type=""
          @click="validatePSE()"
        >
          Pagar con PSE
        </button>
      </div>
    </div>
    <div v-if="errorPSE" class="text-center mt-3">
      <span class="msgError">{{ errorPSE }}</span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { sendFBQEvent } from '@/fbq'
import { GA4AddPaymentAnonymous, GA4PurchaseAnonymous } from '@/GA4/events'
export default {
  data() {
    return {
      bancos: [],
      banco: '',
      nombreTitular: '',
      tipoDoc: '',
      doc: '',
      tipoPersona: '',
      validateBanco: false,
      validateName: false,
      validateTipoDoc: false,
      validateDoc: false,
      validateContribuyente: false,
    }
  },
  computed: {
    ...mapState('payment', [
      'errorPSE',
      'urlPayPSE',
      'dataSummaryPayment',
      'dataCartAnonymous',
      'msgModalExcepcion406',
      'esFE',
    ]),
    ...mapState(['showModal423']),
    /* eslint-disable */
    validateDNI: function() {
      if (this.tipoDoc == 'CC') {
        const re1 = /([0-9]){7}/
        return (
          re1.test(this.doc) && this.doc.length >= 7 && this.doc.length <= 7
        )
      } else if (this.tipoDoc == 'CE') {
        const re2 = /([0-9]){6}/
        return re2.test(this.doc) && this.doc.length == 6
      } else if (this.tipoDoc == 'PAS') {
        const re3 = /([0-9A-Za-z]){5}/
        return re3.test(this.doc) && this.doc.length >= 5
      } else if (this.tipoDoc == 'NIT') {
        return this.doc.length == 9
      }
      return false
    },
    getDataAnalytics() {
      let arrayTemp = []
      this.dataCartAnonymous.productos.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          brand: product.marca,
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
    getDataProductsCRTO() {
      let arrayTemp = []
      this.dataCartAnonymous.productos.forEach(product => {
        arrayTemp.push({
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  async mounted() {
    await this.axios({
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/payment/payu/get_banks_pse',
    })
      .then(response => {
        this.bancos = response.data.bancos
      })
      .catch(e => {
        console.log(e)
      })
  },
  methods: {
    ...mapActions('payment', ['payPSE']),
    validations() {
      this.validateBanco = !this.banco
      this.validateName = !(this.nombreTitular.length > 4)
      this.validateTipoDoc = !this.tipoDoc
      this.validateDoc = !this.validateDNI
      this.validateContribuyente = !this.tipoPersona
    },
    async validatePSE() {
      this.validations()
      if (
        this
          .banco /*&& this.nombreTitular.length > 4 && this.tipoDoc && this.validateDNI && this.tipoPersona*/
      ) {
        this.$store.state.loaderDerek = true
        sendFBQEvent(this, 'InitiateCheckout', {})
        await this.payPSE({
          codPSE: this.banco,
          token: this.$route.params.tokenCart,
          esFE: this.esFE,
        })
          .then(() => {
            if (this.showModal423) {
              this.$store.state.loaderDerek = false
            } else if (this.msgModalExcepcion406) {
              this.$store.state.loaderDerek = false
              this.$bvModal.show('modal-error-406')
            } else {
              GA4AddPaymentAnonymous('PSE')
              GA4PurchaseAnonymous()
              location.href = this.urlPayPSE
            }
          })
          .catch(() => {
            this.$store.state.loaderDerek = false
          })
      } else {
        this.validations()
      }
      //$emit('nextStep', 1)
    },
  },
}
</script>
