<template>
  <div class="payment-gift-card">
    <p>
      Ingresa el código de tu <b>saldo a favor / bono regalo.</b> Recuerda que
      si tu compra es superior al valor del <b>saldo a favor</b>, debes usar un
      método de pago adicional.
    </p>
    <div>
      <div class="check-nit">
        <input
          v-model="code"
          class="payment-input payment-email-input"
          placeholder="Código"
          name="codigogiftcard"
        />
        <button
          class="payment-button-input btn-primary"
          @click="
            AddBonoGift({
              codigo: code,
              token: $route.params.tokenCart,
              esFE: esFE,
            })
          "
        >
          Verificar
        </button>
      </div>
      <div v-if="msgErrorCheckout" class="text-center mt-4">
        <span class="msgError">{{ msgErrorCheckout }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      code: '',
      validate: false,
    }
  },
  computed: {
    ...mapState('payment', ['msgErrorCheckout', 'esFE']),
  },
  methods: {
    ...mapActions('payment', ['AddBonoGift']),
  },
}
</script>
