<template>
  <div>
    <div v-if="dataCartAnonymous" class="form-datos pt-0">
      <div class="payment-input-left">
        <input
          v-model="dataCartAnonymous.carrito.cliente_nombre"
          autocomplete="given-name"
          class="payment-input w-100"
          type="given-name"
          placeholder="Nombre"
          @blur="loseFocus('Nom')"
          @focus="campoNombresRequerido = false"
        />
        <span v-if="campoNombresRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span
          v-if="dataCartAnonymous.carrito.cliente_nombre"
          class="newlatter-error-input"
          >Nombre</span
        >
      </div>
      <div class="payment-input-right">
        <input
          v-model="dataCartAnonymous.carrito.cliente_apellidos"
          autocomplete="family-name"
          class="payment-input w-100"
          type="family-name"
          placeholder="Apellidos"
          @blur="loseFocus('Apell')"
          @focus="campoApellidosRequerido = false"
        />
        <span v-if="campoApellidosRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span
          v-if="dataCartAnonymous.carrito.cliente_apellidos"
          class="newlatter-error-input"
          >Apellidos</span
        >
      </div>
      <div class="payment-input-left">
        <select
          v-model="dataCartAnonymous.carrito.tipodoc"
          class="payment-select w-100"
          @blur="loseFocus('TDoc')"
          @focus="campoTipoDocumentoRequerido = false"
          @change="dataCartAnonymous.carrito.cliente_documento = ''"
        >
          <option value="">Tipo de documento</option>
          <option value="CC">Cédula de ciudadanía</option>
          <option value="CE">Cédula de extranjería</option>
          <option value="PAS">Pasaporte</option>
          <option value="NIT">Nit</option>
        </select>
        <span v-if="campoTipoDocumentoRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span
          v-if="dataCartAnonymous.carrito.tipodoc"
          class="newlatter-error-input"
          >Tipo de documento</span
        >
      </div>
      <div class="payment-input-right">
        <input
          v-model="dataCartAnonymous.carrito.cliente_documento"
          class="payment-input w-100"
          :style="
            dataCartAnonymous.carrito.cliente_documento &&
            !validateDNI &&
            campoDocumentoRequerido
              ? 'border-color: red'
              : ''
          "
          :type="
            dataCartAnonymous.carrito.tipodoc &&
            dataCartAnonymous.carrito.tipodoc == 'PAS'
              ? 'text'
              : 'number'
          "
          placeholder="Documento"
          @blur="loseFocus('Doc')"
          @focus="campoDocumentoRequerido = false"
        />
        <span v-if="campoDocumentoRequerido" class="payment-error-label">{{
          dataCartAnonymous.carrito.cliente_documento
            ? 'El campo ' + dataCartAnonymous.carrito.tipodoc + ' es invalido'
            : 'Este campo es obligatorio'
        }}</span>
        <span
          v-if="dataCartAnonymous.carrito.cliente_documento"
          class="newlatter-error-input"
          >Documento</span
        >
      </div>
      <div class="payment-input-left">
        <input
          v-model="dataCartAnonymous.carrito.cliente_celular"
          :style="
            dataCartAnonymous.carrito.cliente_celular && campoTelefonoRequerido
              ? 'border-color: red'
              : ''
          "
          autocomplete="tel"
          class="payment-input w-100"
          type="tel"
          name="celular"
          placeholder="Celular de contacto"
          @blur="loseFocus('Tel')"
          @focus="campoTelefonoRequerido = false"
        />
        <span v-if="campoTelefonoRequerido" class="payment-error-label">{{
          dataCartAnonymous.carrito.cliente_celular
            ? 'Parece que el número de teléfono no es válido'
            : 'Este campo es obligatorio'
        }}</span>
        <span
          v-if="dataCartAnonymous.carrito.cliente_celular"
          class="newlatter-error-input"
          >Celular de contacto</span
        >
      </div>
    </div>
    <button class="btn-next-step-payment btn-primary" @click="sendData()">
      Siguiente
    </button>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  components: {},
  props: {
    stepPayment: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      createAcound: false,
      shortView: true,
      isAdult: false,
      // Variables para validación de campos //
      campoTelefonoRequerido: false,
      campoNombresRequerido: false,
      campoApellidosRequerido: false,
      campoTipoDocumentoRequerido: false,
      campoDocumentoRequerido: false,
      campomayor18Requerido: false,
      ////////////////////////////////////////
    }
  },
  computed: {
    /* eslint-disable */
    ...mapState('payment', ['dataCartAnonymous']),
    validateEmail: function() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.dataCartAnonymous.carrito.cliente_correo)
    },
    validateDNI: function() {
      if (this.dataCartAnonymous.carrito.tipodoc == 'CC') {
        const re1 = /([0-9]){7}/
        return (
          re1.test(this.dataCartAnonymous.carrito.cliente_documento) &&
          this.dataCartAnonymous.carrito.cliente_documento.length >= 7 &&
          this.dataCartAnonymous.carrito.cliente_documento.length <= 10
        )
      } else if (this.dataCartAnonymous.carrito.tipodoc == 'CE') {
        const re2 = /([0-9]){6}/
        return (
          re2.test(this.dataCartAnonymous.carrito.cliente_documento) &&
          this.dataCartAnonymous.carrito.cliente_documento.length == 6
        )
      } else if (this.dataCartAnonymous.carrito.tipodoc == 'PAS') {
        const re3 = /([0-9A-Za-z]){5}/
        return (
          re3.test(this.dataCartAnonymous.carrito.cliente_documento) &&
          this.dataCartAnonymous.carrito.cliente_documento.length >= 5
        )
      } else if (this.dataCartAnonymous.carrito.tipodoc == 'NIT') {
        return this.dataCartAnonymous.carrito.cliente_documento.length == 9
      }
      return false
    },
    validatePhone: function() {
      const re = /^3[0-9]{9}/
      return (
        re.test(this.dataCartAnonymous.carrito.cliente_celular) &&
        this.dataCartAnonymous.carrito.cliente_celular.length < 11
      )
    },
  },
  watch: {
    isAdult() {
      this.$store.state.payment.dataCartAnonymous.carrito.isAdult = this.isAdult
    },
    dataCart() {
      if (
        this.dataCartAnonymous.carrito.carrito_editar == 1 &&
        this.dataCartAnonymous.carrito.cliente_direccion == ''
      ) {
        this.$store.state.payment.dataCartAnonymous.carrito.isAdult = false
      } else {
        this.$store.state.payment.dataCartAnonymous.carrito.isAdult = true
        if (this.dataCartAnonymous.carrito.carrito_editar == 0) {
          this.shortView = true
        }
      }
    },
  },
  methods: {
    ...mapActions('payment', ['updateInfoCartAnonymous']),
    loseFocus(input) {
      if (input == 'Tel') {
        this.campoTelefonoRequerido = !this.validatePhone
      }
      if (input == 'Nom') {
        const re = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]+$/;
        this.campoNombresRequerido = !(
          this.dataCartAnonymous.carrito.cliente_nombre.length >= 4
          && re.test(this.dataCartAnonymous.carrito.cliente_nombre)
        )
      }
      if (input == 'Apell') {
        const re = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]+$/;
        this.campoApellidosRequerido = !(
          this.dataCartAnonymous.carrito.cliente_apellidos.length >= 4
          && re.test(this.dataCartAnonymous.carrito.cliente_apellidos)
        )
      }
      if (input == 'Doc') {
        this.campoDocumentoRequerido = !this.validateDNI
      }
      if (input == 'TDoc') {
        this.campoTipoDocumentoRequerido = !this.dataCartAnonymous.carrito
          .tipodoc
      }
    },
    async sendData() {
      if (
        this.dataCartAnonymous.carrito.tipodoc &&
        this.validateDNI &&
        this.dataCartAnonymous.carrito.cliente_apellidos.length >= 4 &&
        this.dataCartAnonymous.carrito.cliente_nombre.length >= 4 &&
        this.validatePhone
      ) {
        this.$store.state.loaderDerek = true
        await this.updateInfoCartAnonymous({
          step: '2',
          token: this.$route.params.tokenCart,
        })
        this.$store.state.loaderDerek = false
      } else {
        this.loseFocus('Tel')
        this.loseFocus('Nom')
        this.loseFocus('Apell')
        this.loseFocus('Doc')
        this.loseFocus('TDoc')
      }
    },
  },
  mounted() {
    this.$store.state.payment.dataCartAnonymous.carrito.isAdult = false
    if (
      this.dataCartAnonymous.carrito.carrito_editar == 1 &&
      this.dataCartAnonymous.carrito.cliente_direccion == ''
    ) {
      this.$store.state.payment.dataCartAnonymous.carrito.isAdult = false
    } else {
      this.$store.state.payment.dataCartAnonymous.carrito.isAdult = true
      if (this.dataCartAnonymous.carrito.carrito_editar == 0) {
        this.shortView = true
      }
    }
  },
}
</script>
