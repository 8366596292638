<template>
  <div class="content-payment-addi">
    <p class="text-center">
      <b>
        Finaliza tu compra con ADDI Es simple, rápido y seguro:
      </b>
    </p>
    <p class="text-center mb-4">
      Paga a cuotas con ADDI
      <br />
      <span style="font-size: 12px;">*sujeto a aprobación</span>
    </p>
    <div class="d-flex flex-wrap justify-content-center mb-4">
      <div class="col-12 col-sm-6 col-lg d-flex px-sm-2">
        <p class="box-info-addi">
          Sin tarjeta de crédito y en minutos
        </p>
      </div>
      <div class="col-12 col-sm-6 col-lg d-flex px-sm-2">
        <p class="box-info-addi">
          Paga tu primera cuota un mes después de la compra.
        </p>
      </div>
      <div class="col-12 col-sm-6 col-lg d-flex px-sm-2">
        <p class="box-info-addi">
          Proceso 100% online. Sin papeleo ni costos ocultos
        </p>
      </div>
    </div>
    <p class="text-center mb-4">¡Solo necesitas tu cédula y WhatsApp!</p>
    <div class="d-flex justify-content-center mb-4 col-12 col-lg-8 mx-auto">
      <button class="btn-primary btn-finalize-payment w-100" @click="payAddi()">
        Pagar con Addi
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { sendFBQEvent } from '@/fbq'
import { GA4AddPaymentAnonymous, GA4PurchaseAnonymous } from '@/GA4/events'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState('payment', ['dataCartAnonymous']),
  },
  methods: {
    async payAddi() {
      this.$store.state.loaderDerek = true
      let data = new FormData()
      data.append('token', this.$route.params.tokenCart)
      sendFBQEvent(this, 'InitiateCheckout', {})
      this.errorPaymentAddi = ''
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/addi/compra',
        data: data,
      })
        .then(response => {
          GA4AddPaymentAnonymous('Addi')
          GA4PurchaseAnonymous()
          location.href = response.data
        })
        .catch(err => {
          this.$store.state.loader = false
          if (err.response.status == 423) {
            this.$bvModal.show('modal-products-sold-out')
          } else if (err.response.status == 406) {
            this.$bvModal.show('modal-error-406')
            this.msgModalExcepcion406 = err.response.data.errors.bono_regalo[0]
            this.btnModalExcepcion406 = 'addi'
          } else {
            this.errorPaymentAddi = err.response.data.response.msg
          }
        })
    },
  },
}
</script>
