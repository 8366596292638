<template>
  <div class="col-12 datos-content p-0">
    <div
      v-if="dataCartAnonymous.carrito.direcciones.length > 0 && !editData"
      class="form-datos pt-0"
    >
      <div
        v-for="(dir1, index) in dataCartAnonymous.carrito.direcciones"
        :key="index + isEdit"
        class="col-12 pb-3"
      >
        <div
          class="container-card-address position-relative d-flex flex-wrap align-items-center"
          style="background-color: #F7F3F0;"
          :class="
            dir1.direccion_id == dirSelect
              ? 'container-card-address-selected'
              : ''
          "
          @click="changeDir(dir1)"
        >
          <span
            class="action_card-edit"
            @click="
              dir1.direccion_editar == 0
                ? $bvModal.show('modal-login')
                : dataEdit(dir1)
            "
            >Editar</span
          >
          <input
            v-model="dirSelect"
            type="radio"
            class="my-auto"
            :value="dir1.direccion_id"
          />
          <p class="ps-4">
            {{ dir1.direccion }} <br />
            {{ dir1.barrio }} <br />
            {{
              dir1.ciudad_texto
                .split(',')[0]
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }},
            {{
              dir1.ciudad_texto
                .split(',')[1]
                .trim()
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
            <br />
            {{ dir1.observaciones }}
          </p>
          <br class="d-block d-md-none" />
          <p class="bold ms-auto mt-3 mt-md-0">
            {{ dir1.valor_envio }}
          </p>
        </div>
      </div>
      <div class="col-12 d-flex flex-wrap">
        <div
          v-if="$route.params.tokenCart"
          class="col-12 col-md-6 text-center mt-4 pe-0 pe-md-2"
        >
          <button
            class="w-100 secondary-button px-5"
            @click="
              dataCartAnonymous.carrito.carrito_editar == 0
                ? $bvModal.show('modal-login')
                : (editData = true)
            "
          >
            Agregar Dirección
          </button>
        </div>
        <div class="col-12 col-md-6 text-center my-4 ps-0 ps-md-2 ms-auto">
          <button
            class="btn-next-step-payment btn-primary"
            @click="sendFormAddress()"
          >
            Ir a pagar
          </button>
        </div>
      </div>
    </div>
    <div v-else class="form-datos">
      <div class="payment-input-left">
        <select
          v-model="selectedDep"
          :style="campoDepRequerido ? 'border-color: red' : ''"
          class="payment-select w-100"
          @blur="loseFocus('Dep')"
        >
          <option :value="null">Departamento</option>
          <option
            v-for="(dep, index) in deps"
            :key="index"
            :value="dep.codigo"
            >{{
              dep.descripcion.toLowerCase().replace(/^\w/, d => d.toUpperCase())
            }}</option
          >
        </select>
        <span v-if="campoDepRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span v-if="selectedDep" class="newlatter-error-input"
          >Departamento</span
        >
      </div>
      <div class="payment-input-right">
        <select
          v-model="selectedCity"
          :style="campoCityRequerido ? 'border-color: red' : ''"
          class="payment-select w-100"
          @blur="loseFocus('Ciu')"
        >
          <option :value="null">Ciudad</option>
          <option
            v-for="(city, index) in citys"
            :key="index"
            :value="city.codigo"
            >{{
              city.descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}</option
          >
        </select>
        <span v-if="campoCityRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span v-if="selectedCity" class="newlatter-error-input">Ciudad</span>
      </div>
      <div class="payment-input-left">
        <input
          v-model="dir"
          :style="dir && campoDireccionRequerido ? 'border-color: red' : ''"
          class="payment-input w-100"
          autocomplete="address-line1"
          type="street-address"
          maxlength="50"
          placeholder="Dirección"
          @blur="loseFocus('Dir')"
        />
        <span v-if="campoDireccionRequerido" class="payment-error-label"
          >Este campo es obligatorio</span
        >
        <span v-if="dir" class="newlatter-error-input">Dirección</span>
      </div>
      <div class="payment-input-right">
        <input
          v-model="barrio"
          autocomplete="address-line2"
          class="payment-input w-100"
          type="text"
          maxlength="50"
          placeholder="Barrio"
        />
        <span v-if="barrio" class="newlatter-error-input">Barrio</span>
      </div>
      <div class="w-100 position-relative">
        <input
          v-model="observacionesDir"
          class="payment-input w-100"
          type="text"
          maxlength="50"
          :placeholder="
            observacionesDir
              ? 'Observaciones'
              : 'Observaciones (Ej: Apartamento, torre, piso, etc)'
          "
        />
        <span v-if="observacionesDir" class="newlatter-error-input"
          >Observaciones</span
        >
      </div>
      <div v-if="dataEntregadir" class="mt-4 w-100">
        <p class="bold">Tiempo de entrega apróximado:</p>
        <div class="container-step-envio-payment">
          <p class="mb-0 pe-4">
            Hasta {{ dataEntregadir.dias_entrega }} días hábiles, lo recibes
            entre el {{ dataEntregadir.fechasEnvio.fechaInicio }} y el
            {{ dataEntregadir.fechasEnvio.fechaFinal }}.
          </p>
          <p class="mb-0 bold">
            ${{ new Intl.NumberFormat('es').format(dataEntregadir.total) }}
          </p>
        </div>
      </div>
      <div class="col-12 d-flex flex-wrap mt-4">
        <div
          class="col-12 col-md-6 pe-0 pe-md-2 mt-4 container-btn-add-new-dir-payment"
        >
          <button class="btn-primary px-4 w-100" @click="sendAddress()">
            {{ isEdit ? 'Actualizar dirección' : 'Ir a pagar' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    stepPayment: {
      type: Number,
      required: false,
      default: 0,
    },
    entregarOtraDir: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dirSelect: 0,

      isEdit: false,
      dir: '',
      barrio: '',
      selectedDep: null,
      selectedCity: null,
      observacionesDir: '',
      idDir: null,
      editData: false,
      citys: [],
      dataEntregadir: null,
      campoDireccionRequerido: false,
      campoBarrioRequerido: false,
      campoDepRequerido: false,
      campoCityRequerido: false,
    }
  },
  computed: {
    ...mapState('payment', ['dataCartAnonymous']),
    ...mapState('products', ['deps']),
  },
  watch: {
    dirSelect() {
      let dataDir = this.$store.state.payment.dataCartAnonymous.carrito.direcciones.find(
        e => e.direccion_id == this.dirSelect,
      )
      this.$store.state.payment.dataCartAnonymous.carrito.idDir = this.dirSelect
      this.$store.state.payment.dataCartAnonymous.carrito.cliente_direccion =
        dataDir.direccion
      this.$store.state.payment.dataCartAnonymous.carrito.cliente_barrio =
        dataDir.barrio
      this.$store.state.payment.dataCartAnonymous.carrito.cliente_departamento =
        dataDir.departamento_id
      this.$store.state.payment.dataCartAnonymous.carrito.cliente_ciudad =
        dataDir.ciudad_id
      this.$store.state.payment.dataCartAnonymous.carrito.observaciones =
        dataDir.observaciones
    },
    dir() {
      this.$store.state.payment.dataCartAnonymous.carrito.cliente_direccion = this.dir
    },
    barrio() {
      this.$store.state.payment.dataCartAnonymous.carrito.cliente_barrio = this.barrio
    },
    async selectedDep() {
      if (this.selectedDep) {
        this.$store.state.payment.dataCartAnonymous.carrito.cliente_departamento = this.selectedDep
        this.selectedCity = null
        this.citys = (await this.deps.find(e => e.codigo == this.selectedDep))
          ? await this.deps.find(e => e.codigo == this.selectedDep)?.ciudades
          : null
      }
    },
    async selectedCity() {
      this.$store.state.payment.dataCartAnonymous.carrito.cliente_ciudad = this.selectedCity
      if (this.selectedCity && this.selectedDep) {
        this.$store.state.loaderDerek = true
        var data = new FormData()
        data.append('departamento_codigo', this.selectedDep)
        data.append('ciudad_codigo', this.selectedCity)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/shippings/calculate_shipping',
          data: data,
        })
          .then(response => {
            this.dataEntregadir = response.data.envio
          })
          .catch(e => {
            console.log('Error getEnvio', e)
          })
          .finally(() => {
            this.$store.state.loaderDerek = false
          })
      } else {
        this.dataEntregadir = null
      }
    },
    observacionesDir() {
      this.$store.state.payment.dataCartAnonymous.carrito.observaciones = this.observacionesDir
    },
    async dataCartAnonymous() {
      await this.updateDataDir()
    },
    editData() {
      if (!this.isEdit) {
        this.dir = ''
        this.barrio = ''
        this.selectedDep = null
        this.selectedCity = null
        this.observacionesDir = ''
        this.idDir = null
      }
    },
  },
  async mounted() {
    if (this.entregarOtraDir) {
      this.editData = true
    }
    await this.updateDataDir()
  },
  methods: {
    ...mapActions('products', ['getDataCitys']),
    ...mapActions('payment', ['updateInfoCartAnonymous']),
    async updateDataDir() {
      this.dataCartAnonymous.carrito.idDir = this.dataCartAnonymous.carrito.direcciones[0]?.direccion_id
      this.dirSelect = this.dataCartAnonymous.carrito.direcciones[0]?.direccion_id
      if (!(this.deps.length > 0)) {
        await this.getDataCitys()
      }
      if (!this.dataCartAnonymous.carrito.direccion_id) {
        this.dir = this.dataCartAnonymous.carrito.cliente_direccion
        this.barrio = this.dataCartAnonymous.carrito.cliente_barrio
        this.selectedDep = this.dataCartAnonymous.carrito.cliente_departamento
          ? this.dataCartAnonymous.carrito.cliente_departamento
          : null
        this.citys = (await this.deps.find(e => e.codigo == this.selectedDep))
          ? await this.deps.find(e => e.codigo == this.selectedDep)?.ciudades
          : null
        this.selectedCity =
          this.dataCartAnonymous.carrito.cliente_ciudad != ''
            ? this.dataCartAnonymous.carrito?.cliente_ciudad
            : null
        this.observacionesDir = this.dataCartAnonymous.carrito.observaciones
      }
    },
    loseFocus(input) {
      if (input == 'Dir') {
        this.campoDireccionRequerido = !(this.dir?.length > 4)
      }
      if (input == 'Dep') {
        this.campoDepRequerido = !this.selectedDep
      }
      if (input == 'Ciu') {
        this.campoCityRequerido = !this.selectedCity
      }
    },
    async sendFormAddress() {
      if (this.entregarOtraDir || (!this.isEdit && this.editData)) {
        this.$store.state.payment.dataCartAnonymous.carrito.idDir = ''
      }
      this.$store.state.loaderDerek = true
      await this.updateInfoCartAnonymous({
        step: '3',
        token: this.$route.params.tokenCart,
      })
      this.$store.state.loaderDerek = false
    },
    async sendAddress() {
      if (this.dir?.length > 4 && this.selectedDep && this.selectedCity) {
        this.sendFormAddress()
        // this.$gtag.event('add_shipping_info', {
        //   coupon: this.dataCartAnonymous.cupon,
        //   currency: 'COP',
        //   items: this.cart,
        //   shipping_tier: this.selectedCity,
        //   value: this.dataEntregadir.total,
        // })
      } else {
        this.loseFocus('Dir')
        this.loseFocus('Bar')
        this.loseFocus('Dep')
        this.loseFocus('Ciu')
        this.loseFocus('Dest')
      }
    },
    changeDir(dir) {
      this.dirSelect = dir.direccion_id
      this.dir = dir.direccion
      this.barrio = dir.barrio
      this.selectedDep = dir.departamento_id
      this.selectedCity = dir.ciudad_id ? dir.ciudad_id : null
      this.observaciones = dir.observaciones
    },
  },
}
</script>
