<template>
  <div class="calc-shipping">
    <div class="calc-shipping-content">
      <p v-if="$route.name == 'ProductDetail'" class="info-shipping-title">
        CALCULA EL VALOR DEL ENVÍO:
      </p>
      <p v-if="$route.name == 'ProductDetail'">
        Cotiza el envío de este producto, selecciona el departamento y luego la
        ciudad o municipio.
      </p>
      <div>
        <select
          v-model="selectDep"
          class="form-select"
          aria-label="Selección de ciudad"
        >
          <option value="null">Departamento</option>
          <option v-for="(dep, index) in deps" :key="index" :value="dep">{{
            dep.descripcion.toLowerCase().replace(/^\w/, d => d.toUpperCase())
          }}</option>
        </select>
      </div>
      <div>
        <select
          v-model="selectCity"
          class="form-select"
          aria-label="Selección de ciudad"
        >
          <option value="null">Ciudad</option>
          <option v-for="(city, index) in citys" :key="index" :value="city">{{
            city.descripcion.toLowerCase().replace(/^\w/, d => d.toUpperCase())
          }}</option>
        </select>
      </div>
      <button
        class="secondary-button"
        @click="
          selectDep && selectCity
            ? calculateShipping({
                departamento_codigo: selectDep.codigo,
                ciudad_codigo: selectCity.codigo,
              })
            : ''
        "
      >
        Calcular envío
      </button>
      <div v-if="shippingsTotal && selectCity" class="my-4 text-center">
        <p class="mb-0">
          Envío estándar por:
          <strong
            >${{
              new Intl.NumberFormat('de-DE').format(shippingsTotal.total)
            }}</strong
          >
        </p>
        <p class="mb-0">
          Tiempo de entrega
          <strong>{{ shippingsTotal.dias_entrega }}</strong> días hábiles.
          <br />
          Haz tu pedido hoy y será entregado entre el
          <strong>{{ shippingsTotal.fechasEnvio.fechaInicio }} </strong> y el
          <strong>{{ shippingsTotal.fechasEnvio.fechaFinal }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      citys: [],
      selectCity: null,
      selectDep: null,
    }
  },
  computed: {
    ...mapState('products', ['deps', 'shippingsTotal']),
  },
  watch: {
    selectDep() {
      this.selectCity = null
      this.$store.state.products.shippingsTotal = null
      this.citys = this.selectDep.ciudades
    },
    selectCity() {
      this.$store.state.products.shippingsTotal = null
    },
  },
  mounted() {
    if (!(this.deps.length > 0)) {
      this.getDataCitys()
    }
  },
  methods: {
    ...mapActions('products', ['getDataCitys', 'calculateShipping']),
  },
}
</script>
